const emailField = document.querySelector('.email');
let debounce = false;

emailField.addEventListener('click', () => {
	if (!debounce) {
		debounce = true;
		const emailAddress = emailField.innerHTML;
		emailField.classList.add('active');
		navigator.clipboard.writeText(emailAddress);
		setTimeout(() => {
			emailField.classList.remove('active');
			debounce = false;
		}, 250);
	}
});